<template>
  <div class="SCLTry">
    <h3>SCL-{{postData.S_OP_Json.S_OP_CG_Try_Brand}}</h3>

    <div><el-button type="info" @click="tb">同步数据</el-button></div>
    <p>屈光度</p>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>FK</li>
          <li>SK</li>
          <li>平均K</li>
          <li>视力</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('S_OP_CG_R_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_XOD')" v-model="postData.S_OP_Json.S_OP_CG_R_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_FKOD')" v-model="postData.S_OP_Json.S_OP_CG_R_FKOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_SKOD')" v-model="postData.S_OP_Json.S_OP_CG_R_SKOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_AVGKOD')" v-model="postData.S_OP_Json.S_OP_CG_R_AVGKOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_VAOD')" v-model="postData.S_OP_Json.S_OP_CG_R_VAOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('S_OP_CG_R_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_XOS')" v-model="postData.S_OP_Json.S_OP_CG_R_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_FKOS')" v-model="postData.S_OP_Json.S_OP_CG_R_FKOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_SKOS')" v-model="postData.S_OP_Json.S_OP_CG_R_SKOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_AVGKOS')" v-model="postData.S_OP_Json.S_OP_CG_R_AVGKOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_VAOS')" v-model="postData.S_OP_Json.S_OP_CG_R_VAOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>

    <p>试戴片数据</p>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>类型</li>
          <li>光度</li>
          <li>BC</li>
          <li>DIA</li>
          <li>圆锥编号</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_TypeOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lx"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li><el-input @change="zh('S_OP_CG_Try_DiopterOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_DiopterOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_BCOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_BCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_DIAOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_DIAOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_MRSOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_MRSOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_TypeOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lx"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li><el-input @change="zh('S_OP_CG_Try_DiopterOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_DiopterOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_BCOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_BCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_DIAOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_DIAOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_MRSOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_MRSOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>

    <p>片上验光</p>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>视力</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_XOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_VAOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_VAOD" placeholder="请输入内容"></el-input></li>

        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_XOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_VAOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_VAOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>

    <p>试戴评估</p>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20 xz">
          <li></li>
          <li>中心定位</li>
          <li>角膜覆盖度</li>
          <li>移动度</li>
          <li>松紧度</li>
          <li>散光片定位</li>
        </ul>
        <ul class="content-sr xz">
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LocationOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zxdw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_CoverOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in jmfgd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ActivityOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in ydd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LYJHOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in sjd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_CYLLocationOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in sgpdw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="content-sr padding-20 xz">
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LocationOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zxdw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_CoverOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in jmfgd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ActivityOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in ydd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LYJHOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in sjd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_CYLLocationOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in sgpdw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SCLTry",
  data() {
    return {
      value: '',
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false,

      lx: [
        {
          value: '常规',
          label: '常规'
        },
        {
          value: '电浆',
          label: '电浆'
        },
        {
          value: '散光',
          label: '散光'
        }
      ],

      zxdw: [
        {
          value: '中央',
          label: '中央'
        },
        {
          value: '偏移',
          label: '偏移'
        }
      ],
      jmfgd: [
        {
          value: '完全覆盖',
          label: '完全覆盖'
        },
        {
          value: '不完全覆盖',
          label: '不完全覆盖'
        }
      ],
      ydd: [
        {
          value: '好',
          label: '好'
        },
        {
          value: '差',
          label: '差'
        }
      ],
      sjd: [
        {
          value: '合适',
          label: '合适'
        },
        {
          value: '紧',
          label: '紧'
        },
        {
          value: '松',
          label: '松'
        }
      ],
      sgpdw: [
        {
          value: '顺时针<10度',
          label: '顺时针<10度'
        },
        {
          value: '顺时针10-20度',
          label: '顺时针10-20度'
        },
        {
          value: '顺时针>20度',
          label: '顺时针>20度'
        },
        {
          value: '逆时针<10度',
          label: '逆时针<10度'
        },
        {
          value: '逆时针10-20度',
          label: '逆时针10-20度'
        },
        {
          value: '逆时针>20度',
          label: '逆时针>20度'
        }
      ],

    }
  },
  props: {
    name: {
      default: ''
    },
    postData: {
      default: {}
    }
  },
  methods: {
    handleRemove(file) {
      // console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      // console.log(file);
    },
    tb() {
      let datas = this.$store.state.eyeTest.refractiveData.S_OP_Json
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DSOD', datas.S_OP_R_SS_DSOD)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DCOD', datas.S_OP_R_SS_DCOD)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_XOD', datas.S_OP_R_SS_XOD)

      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DSOS', datas.S_OP_R_SS_DSOS)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DCOS', datas.S_OP_R_SS_DCOS)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_XOS', datas.S_OP_R_SS_XOS)
    },

    zh(key, type=0) {
      let str = this.postData.S_OP_Json[key]
      if (str !== '' && Number(str) > 0) {
        if (type) {
          this.postData.S_OP_Json[key] = "+" + str
        }else {
          this.postData.S_OP_Json[key] = str.toString()
        }
      }else {
        this.postData.S_OP_Json[key] = str !== ''?str.toString():''
      }
    }
  }
}
</script>

<style scoped lang="scss">
h3 {padding-top: .3rem; text-align:left;}
.mar-20 {margin-top: 30px}
.padding-20{padding-top: 15px;}
.srA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  .bts {padding-top: 1em; padding-right: .3rem}
  p {text-align: left;padding-top: .2rem;}
  .content-sr {
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      width: 8vw;
      margin-right: 40px;
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
      }
    }
    li:nth-child(1) {
      width: 3vw;
      text-align: left;
      margin-right: 5px;
    }
  }
  .xz {
    li {width: 12vw}
  }
  .tj {
    input {
      display: block;
      width: 100%;
      outline: none;
      border: 1px solid #000000;
      border-radius: 5px;
      padding: 5px 0;
      text-align: center;
      margin-top: 21px;
    }
  }
  .last {
    margin-left: 50px;
    margin-top: 32px;
  }
}
.bz p {padding-bottom: 10px;font-size: 17px; text-align: left;}
.tp {
  display: flex;
  justify-content: start;
  padding-top: 0.4rem;
}
p {
  padding-top: .3rem;
  text-align: left;
}
</style>
